import Details from './Details';

document.addEventListener('DOMContentLoaded', () => {
  const detailsElements = document.querySelectorAll('.js-details');
  if (!detailsElements.length) {
    return;
  }
  detailsElements.forEach((element) => {
    const detailsElement = new Details(element, {
      // hasAdditionalControl: true,
    });
    detailsElement.mount();
  });
});
