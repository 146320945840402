import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Dropdown extends Component {
  static settings = {
    isPersistent: false,
    hasTransition: false,
    control: null,
    target: null,
    controlSelector: '.js-dropdown-control',
    targetSelector: '.js-dropdown-target',
  };

  #control;
  #target;
  #isHiding;
  #fieldName;
  #hiddenField;
  #handleControlClick;
  #handleItemClick;
  #handleChoose;
  #handleFocusout;
  #handleKeydown;
  #handleOutsideClick;
  #handleTransitionEnd;

  constructor (element, options = {}) {
    super(element, {
      ...Dropdown.settings,
      ...options
    });

    this.#isHiding = false;
    this.#control = this.settings.control ? this.settings.control : this.element.querySelector(this.settings.controlSelector);
    this.#target = this.settings.target ? this.settings.target : this.element.querySelector(this.settings.targetSelector);
    this.#fieldName = this.element.getAttribute('data-field-name');
    this.#hiddenField = document.getElementById(this.#fieldName);

    this.listOptions = this.element.querySelectorAll(".c-dropdown__option");

    if (!this.#control) {
      throw new Error(`Dropdown error: A control with the '${ this.settings.controlSelector }' selector is missing.`);
    }

    if (!this.#target) {
      throw new Error(`Dropdown error: A target with the '${ this.settings.targetSelector }' selector is missing.`);
    }

    if (!this.#control.getAttribute('id')) {
      throw new Error(`Dropdown error: The control needs an id to work correctly.`, this.#control);
    }

    if (!this.#target.getAttribute('id')) {
      throw new Error(`Dropdown error: The target needs an id to work correctly.`, this.#target);
    }

    this.#handleControlClick = this._handleControlClick.bind(this);
    this.#handleChoose = this._handleChoose.bind(this);
    this.#handleItemClick = this._handleItemClick.bind(this);
    this.#handleFocusout = this._handleFocusout.bind(this);
    this.#handleKeydown = this._handleKeydown.bind(this);
    this.#handleOutsideClick = this._handleOutsideClick.bind(this);
    this.#handleTransitionEnd = this._handleTransitionEnd.bind(this);
  }

  get control () {
    return this.#control;
  }

  get target () {
    return this.#target;
  }

  get expanded () {
    return this.#control.getAttribute('aria-expanded') === 'true';
  }

  set expanded (state) {
    this.#control.setAttribute('aria-expanded', `${state}`);
  }

  get isHiding () {
    return this.#isHiding;
  }

  _handleControlClick (e) {
    this.toggle();
  }

  _handleChoose (choice) {
    if (choice.hasAttribute("aria-checked")) {

      this.listOptions.forEach((item) => {
        item.removeAttribute('aria-checked');
        this.#control.removeAttribute('isActive');
      })

    }else {
      this.listOptions.forEach((item) => {
        item.removeAttribute('aria-checked');
        this.#control.removeAttribute('isActive');
      })


      if (this.#hiddenField) {
        this.#hiddenField.value = choice.getAttribute('data-value');
      }

      if (this.element.classList.contains("c-dropdown--quickaccess")) {
      } else {
        choice.setAttribute('aria-checked', 'true');
        // pour branche a un form
        this.#control.setAttribute('isActive', 'true');

        this.element.querySelector(".c-dropdown__label").innerHTML = choice.innerHTML;
      }
    }
  }

  _handleItemClick (e) {
    this.#handleChoose(e.currentTarget);
    this.close(false);
    // e.preventDefault();
  }

  _handleFocusout (e) {
    if (e.relatedTarget && !this.#target.contains(e.relatedTarget) && !this.#control.contains(e.relatedTarget)) {
      this.close(false);
    }
  }

  _handleKeydown (e) {
    // Close on escape
    if (this.expanded && e.keyCode === 27) {
      this.toggle();
    }
  }

  _handleOutsideClick (e) {
    if (!this.#target.contains(e.target) && !this.#control.contains(e.target)) {
      this.close(false);
    }
  }

  _handleTransitionEnd (e) {
    if (e.target === this.#target) {
      this.#target.removeEventListener('transitionend', this.#handleTransitionEnd);
      this.#target.classList.remove('is-hiding');
      this.#isHiding = false;
      this.close();
    }
  }

  open () {
    this.#control.setAttribute('aria-expanded', 'true');
    this.#target.removeAttribute('hidden');
    this.#target.setAttribute('tabindex', '1');

    if (!this.settings.isPersistent) {
      document.addEventListener('focusout', this.#handleFocusout);
      document.addEventListener('click', this.#handleOutsideClick);
    }

    this.fire('open', this);
  }

  close (refocus = true) {
    if (!this.settings.isPersistent) {
      document.removeEventListener('focusout', this.#handleFocusout);
      document.removeEventListener('click', this.#handleOutsideClick);
    }

    this.#control.setAttribute('aria-expanded', 'false');
    this.#target.setAttribute('hidden', '');
    this.#target.classList.remove('is-hiding');
    this.#target.setAttribute('tabindex', '-1');

    if (refocus) {
      this.#control.focus();
    }

    this.fire('close', this);
  }

  hide () {
    this.#target.classList.add('is-hiding');
    this.#isHiding = true;
    this.#target.addEventListener('transitionend', this.#handleTransitionEnd);
  }

  toggle () {
    if (this.settings.hasTransition) {
      this.expanded ? this.hide() : this.open();
    } else {
      this.expanded ? this.close() : this.open();
    }
  }

  mount () {
    this.#target.setAttribute('aria-labelledby', this.#control.getAttribute('id'));
    this.#target.setAttribute('tabindex', '-1');
    this.#target.setAttribute('hidden', '');
    this.#control.setAttribute('aria-haspopup', 'true');
    this.#control.setAttribute('aria-expanded', 'false');
    this.#control.setAttribute('aria-controls', this.#target.getAttribute('id'));

    this.#control.addEventListener('click', this.#handleControlClick);
    this.#target.addEventListener('keydown', this.#handleKeydown);

    for (let i = 0; i < this.listOptions.length; i++) {
      const element = this.listOptions[i];
      element.addEventListener('click', this.#handleItemClick);
    }

    // check if dropdown has hidden input
    if (this.element.getAttribute("data-field-name")) {
      let formName=this.element.getAttribute("data-field-name");
      // check if hidden input has value
      if (document.querySelector("[name='"+formName+"']").value){
        let formValue=(document.querySelector("[name='"+formName+"']").value);
        let fieldGroupOptions = this.element.querySelectorAll(".c-dropdown__option");
        // select value in dropdown
        for (let i = 0; i < fieldGroupOptions.length; i++) {
          const fieldGroupOption = fieldGroupOptions[i];
          if (fieldGroupOption.getAttribute("data-value") == formValue) {
            fieldGroupOption.setAttribute("aria-checked", "true");
          }
        }

        // update placeholder text
        if(this.element.querySelector(".c-dropdown__placeholder")) {
          for (let i = 0; i < fieldGroupOptions.length; i++) {
            const fieldGroupOption = fieldGroupOptions[i];
            if (fieldGroupOption.getAttribute("data-value") == formValue) {
              this.element.querySelector(".c-dropdown__placeholder").innerHTML=fieldGroupOption.innerHTML;
            }
          }
        } else {
          // this.element.querySelector(".c-dropdown__label").innerHTML = choice.innerHTML;
          for (let i = 0; i < fieldGroupOptions.length; i++) {
            const fieldGroupOption = fieldGroupOptions[i];
            if (fieldGroupOption.getAttribute("data-value") == formValue) {
              this.element.querySelector(".c-dropdown__label").innerHTML=fieldGroupOption.innerHTML;
            }
          }
        }
        // highlight if slection  is active
        this.#control.setAttribute("isactive", "true");
      }
    }




    super.mount();
  }

  unmount () {
    if (this.expanded) {
      this.close(false);
    }

    this.#control.removeEventListener('click', this.#handleControlClick);
    this.#target.removeEventListener('keydown', this.#handleKeydown);

    this.#control.removeAttribute('aria-haspopup');
    this.#control.removeAttribute('aria-expanded');
    this.#control.removeAttribute('aria-controls');
    this.#target.removeAttribute('aria-labelledby');
    this.#target.removeAttribute('tabindex');
    this.#target.removeAttribute('hidden');

    super.unmount();
  }
}
