import 'mdn-polyfills/NodeList.prototype.forEach';
import Phone from './Phone';

document.addEventListener('DOMContentLoaded', () => {
  const phoneButtons = document.querySelectorAll('.js-phone');
  if (!phoneButtons.length) {
    return;
  }

  phoneButtons.forEach((element) => {
    const phoneButton = new Phone(element);
    phoneButton.mount(element);
  });
});
