/**
 * Name: Seemore
 * Description: Collapsing section
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Seemore extends Component {
  static settings = {
    i18n: {
      hideSection: 'En voir moins',
      showSection: 'En voir plus',
    }
  };

  #control;
  #content;
  #isOpen;


  #handleControlClick;
  #handleResize;
  #handleToggle;

  constructor (element, options = {}) {
    super(element, {
      ...Seemore.settings,
      ...options
    });

    this.#control = this.element.querySelector('.js-seemore-control');
    this.#content = this.element.querySelector('.js-seemore-content');

    this.#handleControlClick = this._handleControlClick.bind(this);
    this.#handleResize = this._handleResize.bind(this);
  }

  _handleResize() {
    this.createTeaser();
  }

  _handleControlClick (e) {
    this.toggle();
  }

  toggle () {
    this.#isOpen ? this.close() : this.open();
  }

  close () {
    this.#content.setAttribute('mainContentIsHiden', '');
    this.#control.querySelector(".c-button__icon").innerHTML ='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor"  d="M17.75 11H12.5V5.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75V11H5.75c-.41 0-.75.34-.75.75s.34.75.75.75H11v5.25c0 .41.34.75.75.75s.75-.34.75-.75V12.5h5.25c.41 0 .75-.34.75-.75s-.34-.75-.75-.75"/></svg>';
    this.#control.querySelector(".c-button__label").innerText=this.settings.i18n.showSection;
    this.#isOpen = false;
  }

  open () {
    this.#content.removeAttribute('mainContentIsHiden', '');
    this.#control.querySelector(".c-button__label").innerText=this.settings.i18n.hideSection;

    this.#control.querySelector(".c-button__icon").innerHTML='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor"  d="M17.75 11h-12c-.41 0-.75.34-.75.75s.34.75.75.75h12c.41 0 .75-.34.75-.75s-.34-.75-.75-.75"/></svg>';
    this.#isOpen = true;
  }

  mount () {
    this.createTeaser();
    super.mount();
  }

  createTeaser() {
    this.#content.setAttribute('mainContentIsHiden', '');
    this.#control.removeAttribute('aria-hidden', '');
    this.#isOpen = false;
    this.#control.addEventListener('click', this.#handleControlClick);
  }

  unmount () {

    super.unmount();
  }
}
