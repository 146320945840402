import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.next = this.element.querySelector(".swiper-button-next .c-button");
    this.prev = this.element.querySelector(".swiper-button-prev .c-button");

    this.swiperElement = this.element;

    this.spacing = 16;
    this.infinite = false;

    this.swiperElement = this.element;

    this.innerWidth = window.innerWidth;

    this.handleResize = this.handleResize.bind(this);
    this.handleResize = this.handleResize.bind(this);

    this.slidesPerView= "1";

    if (this.swiperElement.classList.contains("c-slider--conseille")) {

      this.spaceBetweenValues = {
        620: {
          spaceBetween: 24
        },
      }
      this.slidesPerView= "auto";
    }

    this.settings = {
      modules: [Navigation],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      loop: this.infinite,
      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChangeTransitionEnd:(swiper)=> {
          this.updateTabIndex(swiper);
        }
      },
      slidesPerView: this.slidesPerView,
      watchSlidesProgress: true,
      spaceBetween: 16,
      breakpoints: {
        620: {
          spaceBetween:10
        },
        690: {
          spaceBetween: 40
        }
      }

    };

    this.swiper;
  }

  handleResize() {
  }

  updateTabIndex(){
    let slides = this.element.querySelectorAll(".swiper-slide");

    for (let i = 0; i < slides.length; i++) {
      const element = slides[i];
      if (element.classList.contains("swiper-slide-active")) {
        let slideButtons=element.querySelectorAll("a");
        for (let index = 0; index < slideButtons.length; index++) {
          const elementButton = slideButtons[index];
          elementButton.setAttribute('tabIndex', '0');
        }
      }else {
        let slideButtons=element.querySelectorAll("a");
        for (let index = 0; index < slideButtons.length; index++) {
          const elementButton = slideButtons[index];
          elementButton.setAttribute('tabIndex', '-1');
        }
      }

    }
  }

  updateNavigation(swiper) {
    this.prev.removeAttribute('disabled');
    this.next.removeAttribute('disabled');
    this.element.removeAttribute('sliderend', '');

    if (swiper.isBeginning) {
      this.prev.setAttribute('disabled', '');
    }
    if (swiper.isEnd) {
      this.next.setAttribute('disabled', '');
      this.element.setAttribute('sliderend', '');
    }

  }



  mount() {
    this.settings = {
      ...this.settings,
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);
    window.addEventListener('resize', this.handleResize);
    this.updateTabIndex();



      this.swiper.on('reachBeginning', () => {
        this.prev.setAttribute('disabled', '');
      });

      this.swiper.on('reachEnd', () => {
        this.next.setAttribute('disabled', '');
      });
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
